import React from 'react'
import PropTypes from 'prop-types'
import { observable, action, computed } from 'mobx'
import styled from 'styled-components'
import { IconButton, RightDivider, TargetBase } from '@code-yellow/spider'
import { theme } from 'styles'
import { opacify } from 'polished'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { Popup } from 'semantic-ui-react'

const KEY_RE = /\{\{((?:[A-Za-z_][A-Za-z0-9_]*)?)(\}{0,2})/g

const TemplateTextArea = styled.textarea`
  font-family: monospace;
  color: transparent !important;
  caret-color: rgba(0, 0, 0, 0.87);
  ::selection {
    background-color: ${opacify(-0.8, theme.primaryColor)};
  }

  /* Make sure word-break is done the same. */
  word-break: break-word;
`

const TemplateWrapper = styled.div`
  position: relative;
`

const TemplateOverlay = styled.div`
  position: absolute;
  left: calc(1em + 1px);
  top: calc(0.78561429em + 1px);
  height: calc(100% - 1.57122858em - 2px);
  pointer-events: none;
  font-family: monospace;
  line-height: 1.2857;
  color: #f00;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  ${({ disabled }) =>
    disabled
      ? `
        opacity: 0.45;
    `
      : ''}

  width: ${({ width = '100%' }) => `calc(${width} - 2em - 2px)`};

  /* Make sure word-break is done the same. */
  word-break: break-word;
  white-space: break-spaces;
`

const TextSpan = styled.span``

const KeySpan = styled.span`
  color: ${theme.primaryColor};
  ${({ error }) =>
    error
      ? `
        text-decoration: underline wavy red;
    `
      : ''}
`

const SuggestionSpan = styled.span`
  color: rgba(0, 0, 0, 0.25);
`

const DelimiterSpan = styled.span`
  color: rgba(0, 0, 0, 0.5);
`

const Options = styled.div`
  position: absolute;
  left: calc(${({ left }) => left}px + 0.75em);
  top: calc(${({ top }) => top + 1}px + 0.78561429em);
  background-color: #fcfcfc;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25em;
  font-family: monospace;
  color: ${theme.primaryColor};
  z-index: 100;
  max-height: 200px;
  overflow-y: scroll;
`

const Option = styled.div`
  padding: 0 0.25em;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: calc(0.25em - 1px);
    border-top-right-radius: calc(0.25em - 1px);
  }
  &:last-child {
    border-bottom-left-radius: calc(0.25em - 1px);
    border-bottom-right-radius: calc(0.25em - 1px);
  }
  &:hover {
    background-color: #f0f0f0;
  }
  ${({ active }) =>
    active
      ? `
        background-color: #F0F0F0;
    `
      : ''}
`

const OptionDescription = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8em;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-left: 0.5em;
  position: relative;
  bottom: 0.1em;
`

const InfoIcon = styled(IconButton)`
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
  margin: 0 !important;
`

function parseTemplate(template, cursor = null) {
  const parts = []

  let match
  let pos = 0
  do {
    match = KEY_RE.exec(template)
    if (match) {
      // Skip unterminated keys if they do not contain the cursor
      if (match[2] !== '}}' && (cursor < match.index + 2 || cursor > KEY_RE.lastIndex)) {
        continue
      }
      if (pos !== match.index) {
        parts.push({
          type: 'text',
          content: template.slice(pos, match.index),
          start: pos,
          end: match.index,
        })
      }
      parts.push({
        type: 'key',
        content: match[1],
        start: match.index,
        end: KEY_RE.lastIndex,
      })
      pos = KEY_RE.lastIndex
    }
  } while (match)

  if (pos !== template.length) {
    parts.push({
      type: 'text',
      content: template.slice(pos),
      start: pos,
      end: template.length,
    })
  }

  return parts
}

const Nl2Br = ({ content }) =>
  content.split('\n').map((line, i) => (
    <React.Fragment key={i}>
      {i !== 0 && <br />}
      {line}
    </React.Fragment>
  ))

export default class TargetTemplate extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: PropTypes.string,
    allowedKeys: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired
    ),
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    ...TargetBase.defaultProps,
    disabled: false,
  }

  constructor(...args) {
    super(...args)
    this.textAreaRef = this.textAreaRef.bind(this)
    this.overlayRef = this.overlayRef.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.onSelectionChange = this.onSelectionChange.bind(this)
    this.renderPart = this.renderPart.bind(this)
    this.renderOption = this.renderOption.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.setOptionsPos = this.setOptionsPos.bind(this)
  }

  componentDidMount() {
    this.selectionInterval = setInterval(this.onSelectionChange, 1000 / 60)
  }

  componentWillUnmount() {
    clearInterval(this.selectionInterval)
  }

  @computed get template() {
    return parseTemplate(this.value, this.selectionStart === this.selectionEnd ? this.selectionStart : null)
  }

  @observable selectionStart = null
  @observable selectionEnd = null

  @action onSelectionChange() {
    const { selectionStart, selectionEnd } =
      this.textArea === document.activeElement ? this.textArea : { selectionStart: null, selectionEnd: null }
    if (selectionStart !== this.selectionStart || selectionEnd !== this.selectionEnd) {
      this.selectionStart = selectionStart
      this.selectionEnd = selectionEnd
      this.activeOption = 0
    }
  }

  @computed get activeIndex() {
    if (this.selectionStart === null || this.selectionStart !== this.selectionEnd) {
      return null
    }

    const index = this.template.findIndex(
      ({ type, start, content }) =>
        type === 'key' && this.selectionStart >= start + 2 && this.selectionStart <= start + 2 + content.length
    )

    return index === -1 ? null : index
  }

  @observable activeOption = 0

  @computed get options() {
    const { allowedKeys } = this.props

    if (!allowedKeys || this.activeIndex === null) {
      return []
    }
    const { content, start } = this.template[this.activeIndex]
    const prefix = content.slice(0, this.selectionStart - start - 2).toLowerCase()
    let outputDict = allowedKeys
    //If the sscc code flag is on, add sscc to the suggestion list
    if(isFeatureFlagEnabled('sscc_qr_code')){
      outputDict = allowedKeys.concat([{ key: 'sscc', description: t('printStep.field.labelTemplate.keys.sscc') }])
    }

    return outputDict.filter(({ key }) => key.toLowerCase().startsWith(prefix))
  }

  @observable textArea = null
  overlay = null
  optionsNode = null

  textAreaRef(ref) {
    this.textArea = ref
  }

  overlayRef(ref) {
    this.overlay = ref
  }

  @observable optionsPos = null

  setOptionsPos() {
    if (!this.overlay) {
      return
    }

    let pos = null
    for (let i = 0; i < this.overlay.children.length; i++) {
      const node = this.overlay.children[i]
      if (node.classList.contains('activePart')) {
        const overlayRect = this.overlay.getBoundingClientRect()
        const nodeRect = node.getBoundingClientRect()
        pos = {
          left: nodeRect.left - overlayRect.left,
          top: nodeRect.bottom - overlayRect.top,
        }
        break
      }
    }
    if (
      pos === null
        ? this.optionsPos !== null
        : this.optionsPos === null || this.optionsPos.left !== pos.left || this.optionsPos.top !== pos.top
    ) {
      this.optionsPos = pos
    }
  }

  onChange(e) {
    super.onChange(e.target.value)
    this.activeOption = 0
  }

  onScroll(e) {
    this.overlay.scrollTop = e.target.scrollTop
  }

  setOption(index) {
    const { start, end } = this.template[this.activeIndex]
    const value = this.value.slice(0, start) + '{{' + this.options[index].key + '}}' + this.value.slice(end)
    const pos = start + this.options[index].key.length + 4
    this.onChange({ target: { value } })
    setTimeout(() => {
      this.textArea.selectionStart = pos
      this.textArea.selectionEnd = pos
    }, 0)
  }

  scrollToActiveOption() {
    const optionNode = this.optionsNode.children[this.activeOption]

    const minScrollTop = Math.max(optionNode.offsetTop + optionNode.clientHeight - this.optionsNode.clientHeight, 0)
    const maxScrollTop = Math.min(optionNode.offsetTop, this.optionsNode.scrollHeight - this.optionsNode.clientHeight)

    this.optionsNode.scrollTop = Math.min(Math.max(this.optionsNode.scrollTop, minScrollTop), maxScrollTop)
  }

  @action onKeyDown(e) {
    if (this.options.length > 0) {
      if (e.key === 'ArrowDown') {
        this.activeOption = (this.activeOption + 1) % this.options.length
        this.scrollToActiveOption()
        e.preventDefault()
      } else if (e.key === 'ArrowUp') {
        this.activeOption = (this.activeOption - 1 + this.options.length) % this.options.length
        this.scrollToActiveOption()
        e.preventDefault()
      } else if (e.key === 'Enter') {
        this.setOption(this.activeOption)
        e.preventDefault()
      }
    }
  }

  renderPart({ type, content, start, end }, i) {
    const { allowedKeys } = this.props
    let outputDict = allowedKeys
    //Add sscc to the allowed slugs so it doesn't get underlined as an error
    if(isFeatureFlagEnabled('sscc_qr_code')){
      outputDict = allowedKeys.concat([{ key: 'sscc', description: t('printStep.field.labelTemplate.keys.sscc') }])
    }

    switch (type) {
      case 'text':
        return (
          <TextSpan key={i}>
            <Nl2Br content={content} />
          </TextSpan>
        )
      case 'key':
        const endDelimiter = '}'.repeat(end - start - content.length - 2)
        return (
          <React.Fragment>
            <DelimiterSpan>{'{{'}</DelimiterSpan>
            {i === this.activeIndex ? (
              <React.Fragment>
                <KeySpan className="activePart" error={this.options.length === 0}>
                  <Nl2Br content={content.slice(0, this.selectionStart - start - 2)} />
                </KeySpan>
                {this.options.length !== 0 && (
                  <SuggestionSpan>
                    <Nl2Br content={this.options[this.activeOption].key.slice(this.selectionStart - start - 2)} />
                  </SuggestionSpan>
                )}
              </React.Fragment>
            ) : (
              <KeySpan error={!outputDict.some(({ key }) => key === content)}>
                <Nl2Br content={content} />
              </KeySpan>
            )}
            {endDelimiter !== '' && <DelimiterSpan>{endDelimiter}</DelimiterSpan>}
          </React.Fragment>
        )
      default:
        throw new Error(`unknown part type: ${type}`)
    }
  }

  renderOption({ key, description }, i) {
    const highlight = this.selectionStart - this.template[this.activeIndex].start - 2
    return (
      <Option
        data-test-option={key}
        key={i}
        active={i === this.activeOption}
        onMouseDown={(e) => {
          if (e.button === 0) {
            this.setOption(i)
            e.preventDefault()
          }
        }}
      >
        <b>{key.slice(0, highlight)}</b>
        {key.slice(highlight)}
        {description && <OptionDescription>{description}</OptionDescription>}
      </Option>
    )
  }

  renderViewTo() {
    return (
      <React.Fragment>
        <RightDivider />
        {super.renderViewTo()}
        <Popup trigger={<InfoIcon name="info circle" />} content={t('template.info')} />
      </React.Fragment>
    )
  }

  renderContent({ disabled, ...props }) {
    setTimeout(this.setOptionsPos, 0)

    return (
      <TemplateWrapper>
        <TemplateTextArea
          innerRef={this.textAreaRef}
          value={this.value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onScroll={this.onScroll}
          onSelect={this.onSelect}
          readOnly={disabled}
          {...props}
        />
        <TemplateOverlay
          data-test-overlay
          innerRef={this.overlayRef}
          disabled={disabled}
          width={this.textArea && `${this.textArea.scrollWidth}px`}
          hasScrollbar={this.textArea && this.textArea.clientHeight < this.textArea.scrollHeight}
        >
          {this.template.map(this.renderPart)}
          <br />
        </TemplateOverlay>
        {this.optionsPos !== null && this.options.length > 0 && (
          <Options data-test-template-options innerRef={(node) => this.optionsNode = node} {...this.optionsPos}>{this.options.map(this.renderOption)}</Options>
        )}
      </TemplateWrapper>
    )
  }
}
