import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { StorageLocationStore } from './StorageLocation'

export class Warehouse extends Model {
  static backendResourceName = 'warehouse'
  static idColor = 'brown'

  @observable id = null
  @observable code = ''
  @observable name = ''
  @observable useStorageLocations = false
  @observable syncLocations = false
  @observable storageLocationsCount = 0 // Annotation

  relations() {
    return {
      storageLocations: StorageLocationStore
    }
  }

  getUrl() {
    return `/assets/warehouse/${this.id}/edit`
  }

  getIdIcon() {
    return 'warehouse'
  }

  get _labelContent() {
    return this.code
  }
}

export class WarehouseStore extends Store {
  Model = Warehouse
  static backendResourceName = 'warehouse'
}
