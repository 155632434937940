import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactIntegration } from './ExactIntegration'
import { Unit4Integration } from './Unit4Integration'
import { NavisionIntegration } from './NavisionIntegration'
import { TransmissionIntegration } from './TransmissionIntegration'
import { SyncErrorStore } from './SyncError'
import { snakeToCamel, camelToSnake } from 'helpers'
import { ExactGlobeIntegration } from './ExactGlobeIntegration'
import { ShippingUnitStore } from './ShippingUnit'
import { RadanIntegration } from './Workstation/RadanIntegration';
import { JanKredietIntegration } from './JanKredietIntegration'
import { UpsIntegration } from './UpsIntegration'
import { PostnlIntegration } from './PostnlIntegration'

export const TYPE_INTEGRATION_RADAN = 'radan'

export const TYPE_ICONS = {
  exact: 'exact',
  unit4: 'unit4',
  navision: 'navision',
  exact_globe: 'exact',
  transmission: 'transmission',
  jan_krediet: 'jan_krediet',
  ups: 'ups',
  postnl: 'postnl',
  [TYPE_INTEGRATION_RADAN]: 'object group outline',
}

export const TYPE_COLORS = {
  exact: '#ff0000',
  unit4: '#BDCF45',
  navision: '#002050',
  exact_globe: '#ff0000',
  transmission: '#70b3e6',
  jan_krediet: '#C6152F',
  ups: '#5E4427',
  postnl: '#FFA500',
  [TYPE_INTEGRATION_RADAN]: '#262626',
}

export class Integration extends Model {
  static backendResourceName = 'integration';
  static omitFields = ['type', 'syncErrorCount'];
  //In the frontend we need a distinction between ERPTYPES, CARRIERTYPES and WORKSTATIONTYPES
  static ERPTYPES = ['exact', 'unit4', 'navision', 'exact_globe'];
  static CARRIERTYPES = ['transmission', 'jan_krediet','ups', 'postnl'];
  static WORKSTATIONTYPES = [TYPE_INTEGRATION_RADAN];
  static TYPES = this.ERPTYPES.concat(this.CARRIERTYPES).concat(this.WORKSTATIONTYPES);

  @observable id = null
  @observable type = 'exact'
  @observable name = ''
  @observable syncErrorCount = 0
  @observable lastSyncedAt = null      // Last time the integration sync ran
  @observable lastPerformedAt = null   // Last time a sync request was performed (client -> server sync)
  @observable _isLoadingSyncErrors = 0
  @observable serviceActive = false
  @observable serviceActiveTimestamp = null
  @observable pendingPerformances = 0

  casts() {
    return {
      lastPerformedAt: Casts.datetime,
    }
  }

  @computed get active() {
    if (Integration.ERPTYPES.includes(this.type)){
      return this.specificIntegration.active;
    }
    return true;
  }

  @computed get integrationType(){
    if (Integration.ERPTYPES.includes(this.type)) {
      return 'ERP';
    }
    else if (Integration.CARRIERTYPES.includes(this.type)) {
      return 'CARRIER';
    }
    else if (Integration.WORKSTATIONTYPES.includes(this.type)){
      return 'WORKSTATION';
    }
    else {
      return 'NONE';
    }
  }

  @computed get specificIntegration(){
    // retreive the model for the specific integration, for example get the exactIntegration
    return this[`${snakeToCamel(this.type)}Integration`]
  }

  relations() {
    return {
      exactIntegration: ExactIntegration,
      unit4Integration: Unit4Integration,
      navisionIntegration: NavisionIntegration,
      exactGlobeIntegration: ExactGlobeIntegration,
      transmissionIntegration: TransmissionIntegration,
      janKredietIntegration: JanKredietIntegration,
      upsIntegration: UpsIntegration,
      postnlIntegration: PostnlIntegration,
      syncErrors: SyncErrorStore,
      shippingUnits: ShippingUnitStore,
      radanIntegration: RadanIntegration,
    }
  }

  toBackendAll(...args) {
    const relations = this.__activeCurrentRelations
    this.__activeCurrentRelations = this.__activeCurrentRelations.filter((rel) => (
      !rel.endsWith('Integration') ||
      camelToSnake(rel.slice(0, -'Integration'.length)) === this.type
    ))
    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = relations
    }
  }

  clearSyncErrors() {
    this._isLoadingSyncErrors = true;

    const p = this.api.put(this.url, { sync_errors: [] });

    p.catch(() => {}).then(() => this._isLoadingSyncErrors = false);

    return p;
  }
}

export class IntegrationStore extends Store {
  static backendResourceName = 'integration'
  Model = Integration
}
