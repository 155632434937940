import React, { Component } from 'react'
import { ItemButton } from 'component/AdminOverview'
import { ProductionRequest } from 'store/ProductionRequest'
import { Form, Modal } from 'semantic-ui-react'
import { t } from 'i18n'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { CancelButton, RightDivider, SaveButton, TargetTextArea } from '@code-yellow/spider'
import { showSaveNotification } from 'helpers/notification'

interface ToggleFinishedButtonProps {
    productionRequest: ProductionRequest;
    afterSave?: () => void;
}

@observer
export default class ToggleFinished extends Component<ToggleFinishedButtonProps> {
    @observable isOpen = false
    open = (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        this.isOpen = true;
    }
    close = () => this.isOpen = false

    toggleFinishedStatus = async () => {
        const { productionRequest, afterSave } = this.props

        await productionRequest.toggleFinishedStatus()
        showSaveNotification()
        afterSave?.()

        this.close()
    }
    render() {
        const { productionRequest, ...props } = this.props

        return (
            <Modal data-test-finish-modal
                dimmer={{
                    // Make sure clicking on dimmer doesn't open / close purchase order.
                    onClick: (e) => {
                        e?.preventDefault()
                        e?.stopPropagation()
                    },
                }}
                open={this.isOpen}
                onClose={this.close}
                trigger={(
                    <ItemButton data-test-finish-order-button={productionRequest.id}
                        icon={productionRequest.finished ? 'redo' : 'check'}
                        label={productionRequest.finished ? t('tooltips.unfinish') : t('tooltips.finish')}
                        onClick={productionRequest.finished ? this.toggleFinishedStatus : this.open}
                        {...props}
                    />
                )}
                closeOnDimmerClick={false}
            >
                <Modal.Header>{t('productionRequest.finishModal.header')}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <TargetTextArea required autoFocus
                            target={productionRequest}
                            name="manualFinishedReason"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton onClick={this.close} />
                    <RightDivider />
                    <SaveButton primary
                        content={t('tooltips.finish')}
                        onClick={this.toggleFinishedStatus} />
                </Modal.Actions>
            </Modal>
        )
    }
}
